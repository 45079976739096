import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import ModuleRenderer from "../components/ModuleRenderer/ModuleRenderer";
import ElfsightInstaWidgetModule from "../components/modules/ElfsightInstaWidgetModule/ElfsightInstaWidgetModule";

const LandingPageTemplate = (props) => {
    const Page = props?.Page;
    const PageModules = props?.Modules;
    const ReviewsData = props?.GlobalConfig?.Elfsight_Reviews;
    const InstaData =  props?.GlobalConfig?.Elfsight_Instagram_Widget;
    const FormData = props?.GlobalConfig?.GetStartedForm;
    const classAttribute = `landingpage-wrap ${Page?.Layout || ''} ${Page?.Pagename ? `page-${Page.Pagename.replace(/\s+/g, '-').toLowerCase()}` : ''} ${Page?.Custom_CSS_Class || ''}`;
  
    return (
        <Layout classAttribute={classAttribute} popularSearch={Page?.Select_Popular_Search}>
            <SEO title={Page?.Meta_Title} description={Page?.Meta_Description} />
            <Breadcrumbs />  
            <ModuleRenderer Modules={PageModules} StrapiId={Page?.strapiId} ggfx_results={Page?.ggfx_results} PageAlias={Page?.Alias} TeamData={Page?.Team_Members} ReviewsData={ReviewsData} FormData={FormData} />
            <ElfsightInstaWidgetModule ModuleData = {InstaData}/>
        </Layout>
    );
};

export default LandingPageTemplate;