import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"
import { Link } from "gatsby";

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import RadioField from './elements/radio'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import { getUser } from "@starberry/myaccount-website-utils"
import Helmet from "react-helmet"
import axios from "axios"
import * as qs from "query-string"
import $ from "jquery"
import loadable from '@loadable/component'
import { useNavigate } from "@reach/router";
const OtherComponent = loadable(() => import('./elements/address-search'))


function MySimpleForm(props) {

  const userData = getUser();

  const [startDate, setStartDate] = useState();
  const [valdatetime, setvaldatetime] = useState();
  const [enqtype, setenqtype] = useState();
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);


  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
      {
        element: "config",
        formname: "Valuation",
        form_type: "valuation",
        error_text: "Highlighted fields are required",
        success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
        email_temp_user: "valuation_user",
        email_temp_admin: "valuation_admin",
        email_server_func: "valuation",
        event_tracking: "valuation",
        page_url: "/valuation"
      },  
      {
        text: '<h2>Please enter your contact details so we can get in touch</h2>',
        element: "heading",
        class: "form-heading",
        step: "stepone",
      },    
      {
        grpmd: "12",
        label: "Name",
        placeholder: "Name",
        name: "name",
        type: "text",
        element: "input",
        step: "stepone",
        required: true,
        labelClass: "content_b-18",
        defaultValue: userData?.name || ``
      },
      {
        grpmd: "12",
        label: "Email",
        placeholder: "Email Address",
        name: "email",
        type: "email",
        element: "input",
        step: "stepone",
        required: true,
        patternchk:"^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z0-9.-]{2,}$",
        labelClass: "content_b-18",
        defaultValue: userData?.email || ``

      },
      {
        grpmd: "12",
        label: "Telephone",
        placeholder: "Phone Number",
        name: "telephone",
        step: "stepone",
        type: "text",
        element: "input",
        required: true,
        labelClass: "content_b-18",
        defaultValue: userData?.mobile || ``

      },  
      {
        grpmd: "12",
        name: "Next",
        type:"button",
        element: "button",
        step: "stepone",
        value: "click",
        class: "btn btn-success stepone",
        labelClass: "content_b-18"
      }, 
      {
        text: '<h2>Thanks Alex, now we need your property details.</h2>',
        element: "heading",
        class: "form-heading",
        step: "steptwo",
      }, 
       
      {
        grpmd: "12",
        label: "Sales",
        placeholder: "Sales",
        value: "Sales",
        name: "enquirytype",
        type: "radio",
        element: "radio",
        checked: "checked",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        labelClass: "content_b-18",
        step: "steptwo"
      },
      {
        grpmd: "12",
        label: "Lettings",
        placeholder: "Lettings",
        name: "enquirytype",
        value: "Lettings",
        type: "radio",
        element: "radio",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        labelClass: "content_b-18",
        step: "steptwo"
      },
     
      {
        grpmd: "12",
        name: "Next",
        type:"button",
        element: "button",
        value: "click",
        class: "btn btn-success",
        labelClass: "content_b-18",
        step: "steptwo"
      }, 
      {
        text: '<h2>Lastly, please select your preferred date and time</h2>',
        element: "heading",
        class: "form-heading",
        step: "stepthree"
      },
      {
        grpmd: "12",
        label: "Select Time & Date:",
        placeholder: "Select Time & Date",
        name: "date_time",
        type: "datetime",
        element: "input",
        // min: today,
        // max: maxday.toString(),
        required: true,
        patternchk: "",
        class: "mb-4 form-control stepthree",
        step: "stepthree"
      },
         
      {
        grpmd: "12",
        name: "Submit",
        type:"submit",
        element: "button",
        value: "submit",
        class: "btn btn-success",
        labelClass: "content_b-18",
        step: "stepthree"
      },
      {
        text: 'By clicking Submit, you agree to our <a href="/terms-and-condition">Terms & Conditions</a> and <a href="/privacy-policy">Privacy Policy</a>',
        element: "html",
        class: "terms",
        step: "stepthree"
      },
      {
        element: "captcha",
        class: "py-2 stepthree",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }
  const getradio = event => {
    event.target.value = event.target.value.trimStart()
    setenqtype(event.target.value)
  }
  const navigate = useNavigate()
  useEffect(() => {
    $('.react-datepicker-wrapper input').keypress(function(e) {
      e.preventDefault();
      return false;
   });

    $('.steptwo.address-wrapper').insertBefore('.btns.steptwo');
    $('#enquirytypechecked').attr('checked',true)
    if (token !== '') {

 const processFromData = async () => {

      let formData = new FormData();

      formData.append('data', JSON.stringify(formvalues));

      postFormData(formData).then(apiRes => {

      // lets send mail
      const axiosOptions_email = {
        url: '/.netlify/functions/server/'+fields[0].email_server_func,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(formvalues),
      }

      axios(axiosOptions_email)
        .then(response => {
          console.log('mail sent!')
        })
        .catch(err =>
          console.log(err)
        );
      });

      formvalues['g-recaptcha-response'] = token;

      const axiosOptions_netlify = {
        url: fields[0].page_url,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(formvalues),
      }

      axios(axiosOptions_netlify)
        .then(response => {
          console.log('data stored')
        })
        .catch(err =>
          console.log(err)
        );
      const url = typeof window !== 'undefined' ? window.location.href : ''  
      if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
        fields[0].formname = 'Get mortgage help';
      }
      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': fields[0].formname
      });

      setShowerror(false);
      setThankyou(true);
      // navigate('/value-my-property/property-valuation/thank-you/')
      //myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  function onClickstep1() {
      setShowerror(true);
      setValidated(true);
      var valname  = $("input[name=name]").val();
      var valemail = $("input[name=email]").val();
      var valtel   = $("input[name=telephone]").val();
      if(valname && valemail && valtel) {
        setShowerror(false);
        setValidated(false);
        $('.valuation-main .stepone').hide();
        $('.valuation-main .steptwo').css('display','inline-block');
        $('.valuation-main .steptwo .form-heading').html('<h2>Thanks '+valname+', now we need your property details.</h2>')
      }
  }
  function onClickstep2() {
    setShowerror(true);
    setValidated(true);
    var valenqtye   = $("input[name=enquirytype]").val();
    var valpost     = $("input[name=address]").val();
    if(valenqtye && valpost) {
        setShowerror(false);
        setValidated(false);
        $('.valuation-main .steptwo').hide();
        $('.valuation-main .stepthree').show();
    }
  }
  const handleSubmit = event => {

    const form = event.currentTarget;
    const formsdata = (event.target);
    setvaldatetime($("input[name=date_time]").val())
    setenqtype($("input[name=enquirytype]:checked").val())
    $("input[name=enquirytype]").val('Sales')
    if(enqtype) {
      $("input[name=enquirytype]").val(enqtype)
    }
   
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      //myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {
      $('.valuation-main .stepthree').hide();
      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? enqtype : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;

      setFormvalues(json);

      recaptchaRef.current.execute();
      setToken("aaa")
      setValidated(false);

      // reset form
      const form = event.target
      //form.reset();
      setStartDate()
      // navigate('/value-my-property/property-valuation/thank-you/')
    }
  };
  sessionStorage.setItem('time', valdatetime)
  //var valdatetime = $("input[name=date_time]").val();

  return (
    <div className="form stbform valuation-main">

    {showerror && <div className="alert-error">
      <p>{fields[0].error_text}</p>
    </div>}
    <Helmet
                script={[{ type: "text/javascript", innerHTML: '(function() {  gtag("event", "conversion", {"send_to": "AW-10960411512/9CJyCMm89NMDEPi2quoo"}); }) ();' }]} />


    {/* {showthankyou && <div className="alert-success">
      <p>All done! We look forward to seeing you on {valdatetime}.</p>
      <div class="btn-wrap">
      <a class="btn btn-success" href="/property/for-sale/in-london/">Find a Property</a>
      <a class="btn btn-success" href="/contact/">Contact Us</a>
      </div>

    </div>} */}

    
      <Form id="valuationform" className="contact-form" name={fields[0].formname} method="post" noValidate validated={validated} onSubmit={handleSubmit}  autoComplete={false}>
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={props.to_email_id} />
        <input type="hidden" name="bot-field" />
        <div className="steptwo address-wrapper">
                <OtherComponent/>
        </div>

        {fields.map((field, index) => {

            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  step={field.step}
                  startDate={field.type === 'datetime' ? startDate : ''}
                  setStartDate={field.type === 'datetime' ? setStartDate : ''}                                   
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  defaultValue={field?.defaultValue}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  checked={field.checked}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={getradio}                  
                  step={field.step}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                  step={field.step}
                />
              );
            }
            if ("heading" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                  step={field.step}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  step={field.step}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  handlechange={onClickstep1} 
                  handlechange2={onClickstep2}
                />
              );
            }
          })
        }
            <div ref={myRef} />

      </Form>
    </div>
  );
}


const MySimpleFormPage = (props) => (
  <MySimpleForm to_email_id={props.to_email_id}  />
)

export default MySimpleFormPage